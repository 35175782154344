#contactButtonContainer {
    margin-top: 3rem;
}

#promiseIcon {
    display: block;
    height: 3rem;
    width: auto;
    color: #FFF;
    margin: auto;
    margin-top: 2rem;
}

.flip-card-container-team {
    width: 283px;
    height: 18rem;
    margin: 0;
    perspective: 1000px;
}

#teamCard {
    width: 100%;
    margin: 1rem;
    height: 16rem;
    border: 1px solid #25282c;
}

#teamCardPhoto {
    height: 9rem;
    width: auto;
    margin: auto;
    margin-top: 1rem;
}

#teamCardName {
    margin-top: 1rem;
}

#teamCardBody {
    padding: 0;
}

#teamCardBodyText {
    padding: 1rem;
    text-align: left;
}

.flip-card-container-team:hover .flip-card, .flip-card-container-team:focus-within .flip-card {
    transform: rotateY(180deg);
}

@media only screen and (max-width: 600px) {
    #teamCard {
        width: 20rem;
    }
}
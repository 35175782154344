#pageCardDarkBullets {
    width: 25rem;
    margin: 1rem;
    height: 23rem;
    background-color: #25282c;
    display: table;
}

#pageCardTransparentBodyBullets {
    display: table-cell;
    vertical-align: middle;
}

@media only screen and (max-width: 600px) {
    #pageCardDarkBullets {
        height: auto;
        width: auto;
    }
}
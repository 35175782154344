#privacyHeaderContainer {
    margin-top: 3rem;
}

#privacyHeader {
    margin-top: 3rem;
    color: #f45858;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 3rem;
}

#privacyBody {
    margin: auto;
    margin-top: 1rem;
    text-align: left;
    width: 90%;
    margin-bottom: 2rem;
    color: #9ca9b3;
}

.privacySubHeader {
    color: #9ca9b3;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 2rem;
    display: block;
}

.privacySubSubHeader {
    color: #9ca9b3;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 1.5rem;
    margin-top: 1rem;
    display: block;
}

.privacyBodyText {
    display: block;
    margin: 0.5rem 0 0 0;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    text-align: justify;
}

@media only screen and (max-width: 600px) {
    #privacyHeader {
        font-size: 2rem;
    }
    .privacySubHeader {
        color: #f45858;
        font-family: 'Inter', sans-serif;
        font-weight: 700;
        font-size: 1.5rem;
    }
}
.certificateOfCurrencyReponseContainer {
    margin: auto;
    margin-top: 2rem;
}

.certificateOfCurrencyReponseText {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 16px;
    text-align: left;
    display: block;
    color: #333333;
    text-decoration: none;
}

#certificateOfCurrencySubmitButton {
    color: #FFF;
    background-color: #F45858;
    margin-top: 1rem;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    padding: 11px 31px;
    height: 48px;
    border: none;
    width: 100%;
}

#certificateOfCurrencySubmitButton:focus {
    outline: 0;
    box-shadow: 0 0 0 0;
}

#certificateOfCurrencySubmitButton:hover {
    background-color: #ffbe37;
}

#certificateOfCurrencyViewIcon {
    height: 1.3rem;
    width: auto;
}
#proposalHeading {
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    display: block;
    margin-bottom: 1rem;
}

#proposalFormWrapper {
    width: 100%;
    margin: auto;
}

#proposalFormContainer {
    margin: auto;
    max-width: 28rem;
    text-align: left;
}

#proposalForm {
    margin-top: 1rem;
}

.proposalFormInput {
    max-width: 40rem;
    margin: auto;
}

#proposalSubmitButtonContainer {
    margin: auto;
    text-align: center;
}

#proposalSubmitButton {
    color: #FFF;
    background-color: #F45858;
    margin-top: 1rem;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    padding: 11px 31px;
    height: 48px;
    border: none;
    width: 100%;
}

#proposalSubmitButton:focus {
    outline: 0;
    box-shadow: 0 0 0 0;
}

#proposalSubmitButton:hover {
    background-color: #ffbe37;
}

.proposalErrorPrompt {
    font-size: 12.8px;
    color: red;
}

#submissionSuccessText {
    text-align: center;
}

@media only screen and (max-width: 600px) {
    #proposalFormWrapper {
        width: 90%;
    }
}
#portfolioCard {
    width: 25rem;
    margin: 1rem;
    height: 26rem;
    background-color: #25282c;
}

#portfolioCardBody {
    padding-top: 0.5rem;
}

#portfolioCardTitle {
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    text-align: left;
    margin-top: 1rem;
    margin-left: 1rem;
    font-size: 1.1rem;
}

#portfolioCardBlurb, #portfolioCardBackText {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    margin: auto;
    text-align: left;
    color: #9ca9b3;
    font-size: 15px;
}

#portfolioCardBackText {
    position: absolute;
    top: 0;
    margin: 1rem;
    color: #FFF;
    font-size: 16px;
}

.flip-card-container {
    width: 310px;
    height: 448px;
    margin: 0;
    perspective: 1000px;
}

.flip-card {
    width: inherit;
    height: inherit;
    position: relative;
    transform-style: preserve-3d;
    transition: .6s .1s;
}

.flip-card-container:hover .flip-card, .flip-card-container:focus-within .flip-card {
    transform: rotateY(180deg);
}

.card-front, .card-back {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    backface-visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-front {
    transform: rotateY(0deg);
    z-index: 2;
}

.card-back {
    transform: rotateY(180deg);
    z-index: 1;
}

#portfolioCardImage {
    height: 100%;
    overflow-y: hidden;
}

#portfolioCardBackImage {
    filter: brightness(37%);
    height: 100%;
    border-radius: 5px;
}

@media only screen and (max-width: 600px) {
    #portfolioCard {
        width: auto;
    }
}
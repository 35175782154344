#formCardContainer {
    margin-bottom: 1rem;
}

#formCard {
    width: 10rem;
    margin: 1rem;
    height: 11rem;
    background-color: #25282c;
}

#formCardTitle {
    margin: auto;
    margin-top: 0.5rem;
    text-align: left;
    margin-left: 0.5rem;
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 16px;
    border: 0;
    padding: 1rem;
}

#formCardButton:hover, #formCard:hover {
    cursor: pointer;
}

#formCard:hover {
    border: 1px solid #ffbe37;
}

.formCardButtonContainer {
    margin: auto;
    margin-bottom: 0;
    margin-right: 0;
    padding-bottom: 1.1rem;
}

#formCardIcon {
    height: 1.5rem;
    width: auto;
    color: #FFF;
    padding-right: 0.9rem;
}

@media only screen and (max-width: 600px) {

}
#navbarLogo:hover .owners-first-logo-text {
    fill: #ffbe37 !important;
}

.owners-first-logo-text {
    fill: #FFF;
}

#navbar {
    background-color: #f45858 !important;
    z-index: 999;
}

.nav-link {
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
}

.nav-link:hover {
    color: #ffbe37;
    text-decoration: underline;
}

@media only screen and (max-width: 600px) {
    .nav-link {
        font-size: 1.2rem;
        border-bottom: 1px solid rgba(255, 255, 255, 0.372);
    }
}
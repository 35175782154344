#footerContainer {
    background-color: #151619 !important;
    margin: auto;
    padding-top: 2rem;
    background-attachment: fixed;
    background-size: cover;
}

#footerCard {
    max-width: 30rem;
    margin: 0 2rem 0 2rem;
}

#footerInfoCard {
    text-align: left;
}

#oLogoContainer {
    align-items: left;
    display: flex;
    justify-content: left;
}

#homeOfOLogo {
    height: 8rem !important;
    width: auto;
}

.footerLink {
    color: #fff;
    text-decoration: none;
    display: block;
    margin-bottom: 1rem;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 1rem;
}

.footerLink:hover {
    color: #ffbe37;
    text-decoration: underline;
}

#footerAddressContainer {
    margin-top: 2rem;
}

.footerAddressItem,
#footerPrivacyStatement,
#footerEM {
    margin: 0.2rem;
    color: #FFF;
    display: block;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 1rem;
}

#footerPh:hover {
    text-decoration: underline;
}

#footerPrivacyStatement {
    margin-top: 2rem;
    font-size: 13px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-style: italic;
}

#footerLogoBig {
    margin: 1rem 0 1rem 0;
}

#footerStrataLogo {
    margin: 2rem 0 2rem 0;
    display: block;
    float: left;
}

#footerEmergency {
    color: #FFF;
    display: block;
    margin-bottom: 1rem;
    font-family: 'Inter', sans-serif;
    font-weight: 800;
    font-size: 1.1rem;
    
}


@media only screen and (max-width: 600px) {
    #footerContainer {
        text-align: center;
    }
}

@media only screen and (max-width: 898px) {
    #homeOfOLogo {
        display: flex;
        margin: auto;
    }

    #footerStrataLogo {
        float: none;
        margin: auto;
        margin-top: 2rem;
    }
}
#reviewCard {
    width: 25rem;
    margin: 1rem;
    height: 12rem;
    background-color: #25282c;
}

#reviewAuthorWrapper {
    color: #F45858;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
}

#reviewAuthor {
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 15px;
}

#reviewAuthorJob {
    color: #F45858;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 15px;
}

#reviewText {
    color: #9ca9b3;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 18px;
    display: block;
}

#reviewLineBreak {
    color: #9ca9b3;
}

@media only screen and (max-width: 600px) {
    #reviewCard {
        height: auto;
        width: auto;
    }
}